//スマホPSDの横幅
$SmartphoneSize: 750;

//vw()
@function vw($size) {
  @return calc(($size / $SmartphoneSize) * 100) + vw;
}

.intro02_point{
  padding-left: 30px;
  padding-right: 30px;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding-left: vw(20);
    padding-right: vw(20);
  }
  li{
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    text-align: left;
    color: #000;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.4em;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(20);
      padding-bottom: vw(20);
      font-size: vw(34);
      position: relative;
      padding-left: vw(65);
      display: block;
    }
    border-bottom: 2px dotted #000;
    text-shadow:1px 2px 0 #FFF, -2px -2px 0 #FFF,
    -2px 2px 0 #FFF, 2px -2px 0 #FFF,
    0px 2px 0 #FFF,  0-2px 0 #FFF,
    -2px 0 0 #FFF, 2px 0 0 #FFF;
    span{
      color: #d02025;
    }
    &::before{
      content: '';
      width: 35px;
      height: 30px;
      margin-right: 10px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(45);
        height: vw(40);
        position: absolute;
        left: 0;
        top: vw(10);
      }
      display: inline-block;
      background: url("../img/icon_check.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

#header_mail_btn img{
  width: auto;
}

.faq_dl{
  dt{
    cursor: pointer;
  }
  dd{
    display: none;
  }
  &.add{
    background: #fcffe0;
  }
}

#header_tel{
  li{
    dl{
      > div{
        display: flex;
        flex-wrap:wrap;
        align-items: center;
        @media only screen and (max-width:$SmartphoneSize + px){
          align-items: flex-start;
        }
        &:first-child{
          margin-bottom: 5px;
        }
        dt{
          color: #fff;
          font-size: 0.6em;
          background: #cbd934;
          padding: 3px 5px;
          margin-right: 10px;
          width: 50px;
          box-sizing: border-box;
          border-radius: 5px;
          @media only screen and (max-width:$SmartphoneSize + px){
            width: vw(90);
            font-size: vw(20);
            padding: vw(3) vw(5);
            margin-right: vw(5);
          }
        }
        dd{
          p{
            font-size: 0.6em;
            display: inline;
            text-align: left;
            @media only screen and (max-width:$SmartphoneSize + px){
              display: block;
              font-size: vw(22);
            }
            &.weekday{
              &::after{
                font-size: 0.5em;
                display: inline;
                content: '/';
                @media only screen and (max-width:$SmartphoneSize + px){
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.td_in_table{
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    width: 100%;
    margin-bottom: vw(30);
  }
  border: 1px solid #ccc;
  tr{
    &:nth-child(odd){
      td{
        background: #f8f8f8;
      }
    }
  }
  th{
    padding: 0.3em;
    background: #DDD;
    border: 1px solid #ccc;
  }
  td{
    padding: 0.3em;
    background: #FFF;
    border: 1px solid #ccc;
  }
}

.cat_nav{
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width:$SmartphoneSize + px){
    justify-content: space-between;
    flex-wrap:wrap;
    margin-bottom: vw(30);
  }
  li{
    margin-right: 10px;
    @media only screen and (max-width:$SmartphoneSize + px){
      width: 49%;
      margin-right: 0;
    }
    a{
      display: block;
      background: #bbc73e;
      text-align: center;
      color: #fff;
      padding: 5px 15px;
      border-radius: 10px;
      text-decoration: none;
      &::before{
        content :'▶';
        margin-right: 5px;
        display: inline;
      }
      @media only screen and (max-width:$SmartphoneSize + px){
        width: 100%;
        padding: vw(10) 0;
        margin-bottom: vw(15);
      }
    }
  }
}
